var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "font-weight-bold primary--text" }, [
          _vm._v("HPC integration")
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c("v-select", {
            attrs: {
              disabled: _vm.fetchingSpaces || _vm.hpcSyncLoading,
              dense: "",
              outlined: "",
              items: _vm.orgNames,
              label: "Select organization"
            },
            model: {
              value: _vm.selectedOrg,
              callback: function($$v) {
                _vm.selectedOrg = $$v
              },
              expression: "selectedOrg"
            }
          }),
          _vm.selectedOrg && _vm.selectedOrgHasHPC === false
            ? _c(
                "v-alert",
                {
                  staticClass: "my-5",
                  attrs: { type: "warning", prominent: "", text: "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { "max-width": "900px" }
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("HPC not available")
                      ]),
                      _vm.isOrgManager
                        ? _c("span", { staticClass: "mt-1" }, [
                            _vm._v(
                              " The current subscription of the selected organization does not include organization-wide HPC integration. To request a subscription upgrade, please reach out to us via "
                            ),
                            _c("a", { on: { click: _vm.showIntercomChat } }, [
                              _vm._v("Intercom")
                            ]),
                            _vm._v(" or send an email to "),
                            _c(
                              "a",
                              {
                                attrs: { href: "mailto:support@nuvolos.cloud" }
                              },
                              [_vm._v("support@nuvolos.cloud")]
                            ),
                            _vm._v(". ")
                          ])
                        : _c("span", [
                            _vm._v(
                              " The current subscription of the selected organization does not include organization-wide HPC integration. Please reach out to your organization manager to submit a request for HPC integration. "
                            )
                          ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.selectedOrg
            ? _c("v-data-table", {
                attrs: {
                  search: _vm.search,
                  loading: _vm.fetchingSpaces || _vm.hpcSyncLoading,
                  headers: _vm.headers,
                  items: _vm.spaceList
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "space-between",
                                align: "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      "hide-details": "",
                                      label: "Show education spaces"
                                    },
                                    model: {
                                      value: _vm.showEducationSpaces,
                                      callback: function($$v) {
                                        _vm.showEducationSpaces = $$v
                                      },
                                      expression: "showEducationSpaces"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: "Filter",
                                      "single-line": "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.long_id",
                      fn: function(ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.long_id) + " ")]
                      }
                    },
                    {
                      key: "item.beegfs_sync",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.beegfs_sync === true
                            ? _c(
                                "v-chip",
                                { attrs: { small: "", color: "success" } },
                                [_vm._v(" Activated ")]
                              )
                            : item.beegfs_sync === false
                            ? _c(
                                "v-chip",
                                { attrs: { small: "", color: "error" } },
                                [_vm._v(" Deactivated ")]
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.beegfs_sync === true
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.selectedOrgHasHPC ===
                                                        false ||
                                                      _vm.isAdminRole(
                                                        item.role
                                                      ) === false ||
                                                      _vm.fetchingSpaces,
                                                    loading: _vm.hpcSyncLoading,
                                                    color: "success",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateFilesSynchronization(
                                                        item.sid,
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [_c("v-icon", [_vm._v("sync")])],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v("Turn synchronization off")
                                  ])
                                ]
                              )
                            : item.beegfs_sync === false
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.selectedOrgHasHPC ===
                                                        false ||
                                                      _vm.isAdminRole(
                                                        item.role
                                                      ) === false ||
                                                      _vm.fetchingSpaces,
                                                    loading: _vm.hpcSyncLoading,
                                                    color: "error",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateFilesSynchronization(
                                                        item.sid,
                                                        true
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("sync_disabled")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v("Turn synchronization on")
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1115982801
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }